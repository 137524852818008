<template>
    <div id="py-list">
        <PYListHeader :title="headerT" :newsType="newType"></PYListHeader>
        <div id="py-list-content">
            <ul>
                <li v-for="item in infoList" :key="item.id" :linkurl="item.link" :newsId="item.news_id" @click="jumpToNewsContent">
                    <p>{{ item.title }}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import PYListHeader from './PYListHeader';
import routerpath from '../router/routerpath'
export default {
    components: { PYListHeader },
    data: function() {
        return {
            infoList: [
                // {id: 1, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
                // {id: 2, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
                // {id: 3, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
                // {id: 4, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
                // {id: 5, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
            ],
            headerT: String,
        }
    },
    props: {
        title: {
            type: String,
            default: '网信信息'
        },
        newType: {
            type: String,
            default: 'wxxx'
        }
    },
    created: function() {
        this.headerT = this.title
    },
    mounted: function() {
        this.axios.get(`/news/${this.newType}?start=0&num=5`)
        .then(res => {
            console.log('res: ', res)
            if (res.data)
            this.infoList = res.data
        })
    },
    methods: {
        jumpToNewsContent(event) {
            // console.log('event: ', event)
            let newsID = event.currentTarget.getAttribute('newsId')
            let link = event.currentTarget.getAttribute("linkurl")
            console.log('this.newType: ', this.newType)
            if (this.newType != 'lypy') {
                this.$router.push({
                    path: routerpath.content,
                    query: {
                        id: newsID,
                    }
                })
            } else {
                window.open(link) // 跳转链接
            }
        },  
    }
    
}
</script>

<style scoped>
ul,li, p{ padding:0;margin:0;list-style:none}

#py-list {
    background: white;
    width: 610px;
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#py-list-content {
    /* background: yellowgreen; */
    width: 610px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

#py-list-content ul {
    margin: 0;
    width: 100%;
    height: 100%;
}

#py-list-content li {
    width: 100%;
    height: 50px;
    line-height: 50px;
    /* margin-left: 20px; */
    background: #F6F7F8;
    color: #666768;
}

#py-list-content li p{
    height: 50px;
    line-height: 50px;
    margin-left: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

</style>