<template>
    <div id="py-jb-page">
        <p>请选择举报方式</p>
        <!-- <el-button type="success" @click="linkToSmjbPage" >实名举报</el-button>
        <el-button type="info" @click="linkToNmjbPage">匿名举报</el-button> -->

        <!-- <div id="right-jb-box"> -->
            <el-button id="right-jb-btn-sm" type="text" native-type="button" @click="linkToNmjbPage"></el-button>
            <el-button id="right-jb-btn-nm" type="text" native-type="button" @click="linkToSmjbPage"></el-button>
        <!-- </div> -->
    </div>
</template>

<script>
// import routerPath from '../router/routerpath'
export default {
    methods: {
        linkToNmjbPage() {
            // this.$router.push(routerPath.nmjb)
            window.open('https://www.henanjubao.com/jblx/nmjb/', '_blank')
        },
        linkToSmjbPage() {
            // this.$router.push(routerPath.smjb)
            window.open('https://www.henanjubao.com/jblx/smjb/', '_blank')
        }
    },
}
</script>

<style scoped>

#py-jb-page {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    /* background: yellowgreen; */
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 40px;
    font-weight: bold;
}

/* .el-button {
    padding: 35px 40px;
    margin: 0 50px;
} */

#right-jb-btn-sm {
    width: 300px;
    height: 180px;
    background: url(../assets/py-nmjb.png) center no-repeat;
    background-size: cover;
    color: rgba(1, 1, 1, 0);
    margin-right: 50px;
}

#right-jb-btn-nm {
    width: 300px;
    height: 180px;
    background: url(../assets/py-smjb.png) center no-repeat;
    background-size: cover;
    color: rgba(1, 1, 1, 0);
}

</style>