<template>
    <div id="list-header">
        <div id="tag-view"></div>
        <p>{{title}}</p>
        <el-button type="primary" @click="jumpToListPage">更多<i class="el-icon-arrow-right el-icon--right"></i></el-button>
    </div>
</template>

<script>
import routerpath from '../router/routerpath'
export default {
    name: "PYListHeader",
    props: {
        title: String,
        newsType: String
    },
    methods: {
        jumpToListPage() {
            this.$router.push({
                path: routerpath.list,
                query: {
                    title: this.title,
                    newsType: this.newsType
                }
            })
        }
    }
}
</script>

<style scoped>

#list-header {
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: white;
}

#tag-view {
    width: 18px;
    height: 46px;
    background: #3587FF;
}

#list-header p {
    width: 490px;
    height: 46px;
    line-height: 46px;
    text-align: left;
    font-size: 30px;
    font-weight: bold;
    color: #3587FF;
}

.el-button--primary {
    background: #3587FF;
    border-color: #3587FF;
}

.el-button {
    border-radius: 5px;
    padding: 10px 20px;
}

</style>