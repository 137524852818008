// 匿名举报页面
<template>
  <div id="py-sm">
    <el-page-header @back="goBack" content="实名举报">
    </el-page-header>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
      size="medium"
    >
      <el-divider>举报人基本信息</el-divider>
      <el-form-item label="真实姓名" prop="username">
        <el-input v-model="ruleForm.username"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="所在地" prop="location">
        <el-select v-model="ruleForm.harmType" placeholder="请选择危害类型">
          <el-option label="政治类" value="zz"></el-option>
          <el-option label="暴恐类" value="bk"></el-option>
          <el-option label="诈骗类" value="zp"></el-option>
          <el-option label="色情类" value="sq"></el-option>
          <el-option label="赌博类" value="db"></el-option>
          <el-option label="谣言类" value="yy"></el-option>
          <el-option label="版权类" value="bq"></el-option>
          <el-option label="疫情不实信息" value="yq"></el-option>
          <el-option label="其他类" value="other"></el-option>
        </el-select>
        <el-select v-model="ruleForm.harmType" placeholder="请选择危害类型">
          <el-option label="政治类" value="zz"></el-option>
          <el-option label="暴恐类" value="bk"></el-option>
          <el-option label="诈骗类" value="zp"></el-option>
          <el-option label="色情类" value="sq"></el-option>
          <el-option label="赌博类" value="db"></el-option>
          <el-option label="谣言类" value="yy"></el-option>
          <el-option label="版权类" value="bq"></el-option>
          <el-option label="疫情不实信息" value="yq"></el-option>
          <el-option label="其他类" value="other"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通讯地址" prop="address">
        <el-input v-model="ruleForm.address"></el-input>
      </el-form-item>

      <el-divider>举报信息</el-divider>
      <el-form-item label="举报类型" prop="jbType">
        <el-select v-model="ruleForm.jbType" placeholder="请选择举报类型">
          <el-option label="网站" value="website"></el-option>
          <el-option label="个人" value="personal"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="网站名称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="信息所在的详细网址" prop="link">
        <el-input v-model="ruleForm.link"></el-input>
      </el-form-item>
      <el-form-item label="举报标题" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="举报内容" prop="content">
        <el-input type="textarea" v-model="ruleForm.content"></el-input>
      </el-form-item>
      <el-form-item label="危害类型" prop="harmType">
        <el-select v-model="ruleForm.harmType" placeholder="请选择危害类型">
          <el-option label="政治类" value="zz"></el-option>
          <el-option label="暴恐类" value="bk"></el-option>
          <el-option label="诈骗类" value="zp"></el-option>
          <el-option label="色情类" value="sq"></el-option>
          <el-option label="赌博类" value="db"></el-option>
          <el-option label="谣言类" value="yy"></el-option>
          <el-option label="版权类" value="bq"></el-option>
          <el-option label="疫情不实信息" value="yq"></el-option>
          <el-option label="其他类" value="other"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择附件" prop="file">
        <el-upload
          class="upload-demo"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        console.log(reg.test(value));
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      ruleForm: {
        jbType: "",
        name: "",
        link: "",
        title: "",
        content: "",
        harmType: "",
        phone: "",
      },
      rules: {
        jbType: [
          { required: true, message: "请选择举报类型", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入网站名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        link: [
          { required: true, message: "请输入信息所在的网址", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入举报标题", trigger: "blur" }],
        content: [
          { required: true, message: "请输入举报内容", trigger: "blur" },
        ],
        harmType: [
          { required: true, message: "请选择危害类型", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "change" },
          { validator: checkPhone, trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    goBack() {
      console.log('go back');
      this.$router.go(-1)
    },
    handleChange() {
      
    }
  },
};
</script>

<style scoped>
#py-sm {
  width: 100%;
  height: 900px;
  /* background: yellowgreen; */
}

form {
  width: 700px;
  height: 100%;
  margin: 50px auto;
}
</style>
