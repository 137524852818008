<template>
  <div id="py-news">
    <el-page-header @back="goBack" :content="title"></el-page-header>
    <div id="py-news-content">
      <div id="content-left">
        <img
          v-for="item in imageList"
          :key="item.selectedImg"
          :src="item.isSelected ? item.selectedImg : item.disImg"
          @click="changeNewsType"
          :newsId="item.newsType"
        /><img />
      </div>
      <div id="content-center">
        <ul>
          <li
            v-for="item in infoList"
            :key="item.id"
            :linkurl="item.link"
            @click="jumpToNewsContent"
            :newsId="item.news_id"
          >
            <p id="title-p">{{ item.title }}</p>
            <p id="time-p">{{ (new Date(item.time)).toLocaleDateString()}}</p>
          </li>
        </ul>
      </div>
      <div id="content-right">
        <div id="py-home-pgt" @click="jumpToListPage">
          <!-- <h1 id="pgt-title">辟谣热度排行</h1>
          <el-divider></el-divider>
          <ul id="pgt-ul">
            <li
              v-for="item in pyListInfo"
              :key="item.id"
              @click="jumpToPyzqContent"
              :newsId="item.news_id"
            >
              <p>{{ item.title }}</p>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageNum"
      @current-change="handleCurrentChange"
      @prev-click="handlePrevClick"
      @next-click="handleNextClick"
    >
    </el-pagination>
  </div>
</template>

<script>
import routerPath from "../router/routerpath";
import qwfb_selected from "../assets/py_list/py_list_qwfb_seleted.png";
import qwfb_dis from "../assets/py_list/py_list_qwfb_disseleted.png";
import yasf_selected from "../assets/py_list/py_list_yasf_seleted.png.png";
import yasf_dis from "../assets/py_list/py_list_yasf_disseleted.png";
import flfg_selected from "../assets/py_list/py_list_flfg_seleted.png";
import flfg_dis from "../assets/py_list/py_list_flfg_disseleted.png";
import bkzs_selected from "../assets/py_list/py_list_bkzs_selected.png";
import bkzs_dis from "../assets/py_list/py_list_bkzs_disseleted.png";

export default {
  name: "PYNewsListPage",
  components: {},
  data: function() {
    return {
      infoList: [],
      pyListInfo: [],
      total: 0,
      pageNum: 20,
      start: 0,
      curPage: 1,
      title: "",
      newsType: "",
      imageList: [
        {
          isSelected: true,
          newsType: 'qwfb',
          title: '权威发布',
          selectedImg: qwfb_selected,
          disImg: qwfb_dis,
        },
        {
          isSelected: false,
          newsType: 'jsal',
           title: '以案说法',
          selectedImg: yasf_selected,
          disImg: yasf_dis,
        },
        {
          isSelected: false,
          newsType: 'fl',
           title: '法律法规',
          selectedImg: flfg_selected,
          disImg: flfg_dis,
        },
        {
          isSelected: false,
          newsType: 'bkzs',
           title: '百科知识',
          selectedImg: bkzs_selected,
          disImg: bkzs_dis,
        },
      ],
    };
  },
  mounted: function() {
    this.title = this.$route.query.title;
    this.newsType = this.$route.query.newsType;
    this.imageList.forEach((item) => {
      if (this.newsType === item.newsType) {
        item.isSelected = true
        this.title = item.title
      } else {
        item.isSelected = false
      }
    })
    console.log(this.$route.query.title, this.$route.query.newsType);
    this.axios.get(`/news/${this.newsType}/count`).then((res) => {
      console.log("res: ", res);
      this.total = res.data["count"];
    });
    // 获取辟谣热度
    this.axios.get(`/news/pyzq?start=0&num=4`).then((res) => {
      console.log("辟谣专区 res: ", res);
      if (res.data) this.pyListInfo = res.data;
    });
    this.getNewsList();
  },
  watch: {
    $route(to, from) {
      console.log("to: ", to);
      console.log("from: ", from);
      this.title = this.$route.query.title;
      this.newsType = this.$route.query.newsType;
      this.imageList.forEach((item) => {
      if (this.newsType === item.newsType) {
        item.isSelected = true
        this.title = item.title
      } else {
        item.isSelected = false
      }
    })
      console.log(this.$route.query.title, this.$route.query.newsType);
      this.axios.get(`/news/${this.newsType}/count`).then((res) => {
        console.log("res: ", res);
        this.total = res.data["count"];
      });
      this.getNewsList();
    },
  },
  methods: {
    jumpToListPage() {
      if (this.$route.path !== routerPath.pyybk) {
        this.$router.push({
          path: routerPath.pyybk,
          query: {
              title: '辟谣清单',
              newsType: 'pyybk'
          }
        });

      }
      // if (this.$route.path !== routerPath.pyzpzb) {
        // this.$router.push({
        //   path: routerPath.pyzpzb,
        //   query: {
        //       title: '辟谣作品展播',
        //       newsType: 'lypy_video'
        //   }
        // });

      // }
    },
    goBack() {
      console.log("go back");
      this.$router.go(-1);
    },
    jumpToPyzqContent(event) {
      // 跳转到辟谣专区
      let newsID = event.currentTarget.getAttribute("newsId");
      console.log("跳转到新闻详情: ", newsID);
      this.$router.push({
        path: routerPath.content,
        query: {
          id: newsID,
        },
      });
    },
    jumpToNewsContent(event) {
      console.log('event: ', event)
      let newsID = event.currentTarget.getAttribute('newsId')
      this.$router.push({
          path: routerPath.content,
          query: {
              id: newsID,
          }
      })
    },
    handleCurrentChange(val) {
      console.log("当前页: ", val);
      if (this.curPage == val) {
        return;
      }
      this.curPage = val;
      this.getNewsList();
    },
    handlePrevClick() {
      // 前一页
      this.curPage--;
      this.getNewsList();
    },
    handleNextClick() {
      // 后一页
      this.curPage++;
      this.getNewsList();
    },
    getNewsList() {
      this.axios
        .get(
          `/news/${this.newsType}?start=${this.start +
            (this.curPage - 1) * this.pageNum}&num=${this.pageNum}`
        )
        .then((res) => {
          console.log("res: ", res);
          this.infoList = res.data;
        });
    },
    changeNewsType(event) {
      let newsID = event.currentTarget.getAttribute('newsID')
      console.log('newsID: ', newsID)
      if (this.newsType != newsID) {
        this.imageList.forEach((item) => {
          if (item.newsType === newsID) {
            item.isSelected = true
            this.title = item.title
          } else {
            item.isSelected = false
          }
        })
        this.newsType = newsID
        this.curPage = 1
        this.getNewsList()
      }
    }
  },
};
</script>

<style scoped>
ul,
li,
p {
  padding: 0;
  margin: 0;
  list-style: none;
}

#py-news {
  width: 1350px;
  margin: 0 auto;
  /* height: 100px; */
  /* background: lightcyan; */
}

#py-news-content {
  width: 100%;
  margin: 0 auto;
  height: 1020px;
  /* background: blue; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

#content-left {
  width: 320px;
  height: 100%;
  /* background: royalblue; */
}

#content-center {
  width: 700px;
  height: 100%;
  /* background: blueviolet; */
}

#content-right {
  width: 300px;
  height: 100%;
  /* background: yellow; */
  display: flex;
  flex-direction: column;
}

ul {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1000px;
  /* background: cornsilk; */
}

li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background: crimson; */
  height: 50px;
}

li p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

li:active {
  background-color: #f4f4f5;
}

#title-p {
  flex-grow: 1;
  text-align: left;
}

#time-p {
  width: 120px;
  text-align: right;
}

.el-pagination {
  margin-top: 20px;
  margin-bottom: 20px;
}

#py-home-pgt {
  width: 100%;
  height: 100px;
  /* background: rosybrown; */
  background-image: url("../assets/home/py-home-right.jpeg");
  background-size: 100% 100%;
  /* margin-left: 15px; */
  margin-top: 0px;
}

#pgt-title {
  margin-top: 120px;
  color: white;
  font-size: 25px;
}

.el-divider--horizontal {
  margin: -15px 0;
}

#pgt-ul {
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  /* background: lemonchiffon; */
}

#pgt-ul li {
  text-align: left;
  margin-left: 10px;
  height: 35px;
  width: 100%;
  line-height: 35px;
}

#pgt-ul li:active {
  background: rgba(1, 1, 1, 0);
}
</style>
