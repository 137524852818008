<template>
  <div id="py-news-content">
    <el-page-header @back="goBack" content="内容"></el-page-header>
    <div id="news-content">
      <h1>{{newsInfo.title}}</h1>
      <div id="content">
        <p id="time">时间：{{newsInfo.time.substring(0, 10)}}</p>
        <p id="source">{{newsInfo.source}}</p>
      </div>
      <div class="py-content" v-html="newsInfo.article">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      newsInfo: {
        title: '',
        time: '',
        source: '',
        article: ''
      }
    }
  },
  mounted() {
    let newsId = this.$route.query.id
    this.axios.get(`/new/${newsId}`)
        .then(res => {
            console.log('res: ', res)
            if (res.data)
            this.newsInfo = res.data
        })
  },
  methods: {
    goBack() {
      console.log('go back');
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped>

ul,li, p{ padding:0;margin:0;list-style:none}

#py-news-content {
  width: 900px;
  margin: 0 auto;
  /* height: 100px; */
  /* background: rosybrown; */
  margin-bottom: 150px;
}

#content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#time {
  margin-right: 50px;
}

.py-content {
  text-align: left;
}
</style>
