<template>
  <div id="py-home-list">
    <PYHomeNewListHeader :imgSrc="imgSrc" :newsType="newType" :title="title"></PYHomeNewListHeader>
    <div id="py-list-content">
      <ul>
        <li v-for="item in infoList" :key="item.news_id" :linkurl="item.link" :newsId="item.news_id"
          @click="jumpToNewsContent">
          <p>{{ item.title }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PYHomeNewListHeader from './PYHomeNewListHeader'
import routerpath from '../router/routerpath'
export default {
  components: {
    PYHomeNewListHeader
  },
  props: {
    newType: String,
    imgSrc: String,
    title: String
  },
  data: function () {
    return {
      infoList: [
        // {id: 1, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 2, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 3, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 4, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 5, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        //  {id: 6, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 7, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 8, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 9, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
      ],
    }
  },
  created() {
    this.axios.get(`/news/${this.newType}?start=0&num=9`)
      .then(res => {
        console.log('res: ', res)
        if (res.data)
          this.infoList = res.data
      })
  },
  methods: {
    jumpToNewsContent(event) {
      console.log('event: ', event)
      let newsID = event.currentTarget.getAttribute('newsId')
      this.$router.push({
        path: routerpath.content,
        query: {
          id: newsID,
        }
      })
    },
  }
}
</script>

<style scoped>
ul,
li,
p {
  padding: 0;
  margin: 0;
  list-style: none
}

#py-home-list {
  width: 683px;
  /* flex-grow: 1; */
  height: 100px;
  /* background: crimson; */
}

#py-list-content {
  /* background: yellowgreen; */
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

#py-list-content ul {
  margin: 0;
  width: 100%;
  height: 100%;
}

#py-list-content li {
  width: 100%;
  height: 50px;
  line-height: 50px;
  /* margin-left: 20px; */
  background: #F6F7F8;
  color: #666768;
}

#py-list-content li p {
  height: 50px;
  line-height: 50px;
  margin-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}</style>
