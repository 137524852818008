<template>
  <div id="py-home">
    <!-- 权威发布 -->
    <div id="py-home-qwfb">
      <PYHomeList newType="qwfb" :imgSrc="home_qwfb" title="权威发布"></PYHomeList>
      <PYIMGList></PYIMGList>
    </div>
    <!-- 举报专区 -->
    <div id="py-home-jbzq">
      <img id="jbzq-img" src="../assets/home/py-home-jyjbzq.png" alt="" @click="jumpToJBZQ">
      <div id="py-carousel">
        <el-carousel height="400px" :interval="5000" arrow="never" indicator-position="none">
          <el-carousel-item>
            <img class="carousel-img" src="../assets/banner/banner01.png" alt="" @click="jumpToJBZQ">
          </el-carousel-item>
          <!-- <el-carousel-item>
            <img class="carousel-img" src="../assets/banner/banner03.jpg" alt="" @click="jumpToListPage">
          </el-carousel-item> -->
        </el-carousel>
      </div>
    </div>
    <!-- 新闻专区 -->
    <div id="py-home-news">
      <PYHomeList style="width: 630px" newType="jsal" :imgSrc="home_yasf" title="以案说法"></PYHomeList>
      <PYHomeList style="width: 630px" newType="fl" :imgSrc="home_flfg" title="法律法规"></PYHomeList>
    </div>

  </div>
</template>

<script>
import PYHomeList from '../components/PYHomeList'
import PYIMGList from '../components/PYIMGList'

import home_yasf from '../assets/home/home_yasf.png'
import home_qwfb from '../assets/home/home_qwfb.png'
import home_flfg from '../assets/home/home_flfg.png'

import routerpath from '../router/routerpath.js'

export default {
  components: {
    PYHomeList,
    PYIMGList
  },
  data: function () {
    return {
      home_yasf: home_yasf,
      home_qwfb: home_qwfb,
      home_flfg: home_flfg
    }
  },
  created() {
    // 获取辟谣热度
     this.axios.get(`/news/pyzq?start=0&num=5`)
        .then(res => {
            console.log('res: ', res)
            if (res.data)
            this.pyListInfo = res.data
        })
  },
  methods: {
    jumpToJBZQ() {
       this.$router.push(routerpath.jbrk)
    }, 
    jumpToPYVideo() {
       this.$router.push(routerpath.jbrk)
    },
    jumpToListPage() {
      this.$router.push({
          path: routerpath.pyzq,
          query: {
              title: '辟谣专区',
              newsType: 'pyzq'
          }
      })
    }
  }
}
</script>

<style scoped>

ul,li, p{ padding:0;margin:0;}
#py-home {
  width: 1300px;
  margin: 0 auto;
  /* background: chocolate; */
  height: 1620px;
  margin-bottom: 30px;
}

#py-home-news {
  width: 100%;
  height: 600px;
  /* background: yellowgreen; */
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  margin-top: 20px;
}

#py-home-jbzq {
  width: 100%;
  height: 450px;
  background: #F4F5F6;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#jbzq-img {
  width: 445px;
  height: 400px;
}

#py-carousel {
  width: 750px;
}

#py-home-qwfb {
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 30px;
}

/* #py-home-pgt {
  width: 617px;
  height: 554px;
  background-image: url('../assets/home/py-home-pgt.png');
  background-size: 100% 100%;
  margin-left: 15px;
  margin-top: 20px;
} */

#pgt-title {
  margin-top: 230px;
  color: white;
}

.el-divider--horizontal {
  margin: -15px 0;
}

#pgt-ul {
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;
  /* background: lemonchiffon; */
}

#pgt-ul li {
  text-align: left;
  margin-left: 50px;
  height: 50px;
  line-height: 50px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 400px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.carousel-img {
  width: 750px;
  height: 400px;
  /* text-align: center; */
}

</style>