<template>
    <div id="py-footer">
        <div class="link">
            <el-link href="http://beian.miit.gov.cn/" target="_blank">豫ICP备05017468号&nbsp;&nbsp;&nbsp;</el-link>
            <img src="../assets/beian.png"/>
            <el-link id="gaba" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41031102000001" target="_blank"> 豫公网安备 41031102000001号</el-link>
        </div>

        <div class="link">
            &copy; {{new Date().getFullYear()}} 洛阳网 &nbsp;&nbsp;&nbsp; 洛阳日报社版权所有 &nbsp; 未经书面授权,请勿转载和建立镜像
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'PYFooter'
}
</script>

<style scoped>
#py-footer {
    background: #3D3E3F;
    width: 100%;
    min-width: 1300px;
    margin-left: 0px;
    margin-right: 0px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}   

.el-link.el-link--default {
    color: #8E8F90;
}

.link {
    color: #8E8F90;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.link img {
    margin-left: 50px;
}

.gaba {
    display: flex !important; 
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}
</style>
