<template>
  <div id="app">
    <PYHeader></PYHeader>
    <PYNavMenu></PYNavMenu>
    <router-view></router-view>
    <PYFooter></PYFooter>
  </div>
</template>

<script>
import PYHeader from './components/PYHeader.vue'
import PYNavMenu from './components/PYNavMenu.vue'
import PYFooter from './components/PYFooter'


export default {
  name: 'App',
  components: {
    PYHeader,
    PYNavMenu,
    PYFooter,
  }
}
</script>

<style>

body {
  margin: 0px !important;
}

.el-header {
  padding: 0px !important;
}

.el-main {
  padding: 0px !important;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  height: 1300px;
}

.el-page-header {
  width: 900px;
  margin: 20px auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

</style>
