<template>
  <div id="py-home-pgt" >
    <div id="pgt-video" @click="jumpToVideoListPage"></div>
    <h1 id="pgt-title"></h1>
    <!-- <el-divider></el-divider> -->
    <ul id="pgt-ul">
      <li
        v-for="item in pyListInfo"
        :key="item.id"
        @click="jumpToPyzqContent"
        :newsId="item.news_id"
      >
        <p>{{ item.title }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import routerpath from '../router/routerpath'
export default {
  data: function() {
    return {
      pyListInfo: [
        // {id: 1, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 2, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 3, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 4, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
        // {id: 5, title: "2020年全国受理网络违法XXXXXXXX2020年全国受理网络违法XXXXXXXX"},
      ]
    }
  },
  created() {
    // 获取辟谣热度
     this.axios.get(`/news/pyybk?start=0&num=6`)
        .then(res => {
            console.log('辟谣专区 res: ', res)
            if (res.data)
            this.pyListInfo = res.data
        })
  },
  methods: {
    jumpToListPage() {
      this.$router.push({
          path: routerpath.pyzq,
          query: {
              title: '辟谣专区',
              newsType: 'pyzq'
          }
      })
    },
    jumpToVideoListPage() {
      console.log("jumpToVideoListPage")
      this.$router.push({
        path: routerpath.pyybk,
        query: {
            title: '辟谣清单',
            newsType: 'pyybk'
        }
      });
      // this.$router.push({
      //   path: routerpath.pyzpzb,
      //     query: {
      //         title: '辟谣作品展播',
      //         newsType: 'lypy_video'
      //     }
      // })
    },
    jumpToPyzqContent(event) {
      // 跳转到辟谣专区
      let newsID = event.currentTarget.getAttribute('newsId')
      console.log('跳转到新闻详情: ', newsID)
      this.$router.push({
                path: routerpath.content,
                query: {
                    id: newsID,
                }
            })
    },
  }
}
</script>

<style scoped>


p {
  padding: 0;
  margin: 0;
  list-style: none;
}

#py-home-pgt {
  width: 617px;
  height: 554px;
  /* background: rosybrown; */
  background-image: url('../assets/home/py-home-pgt.png');
  background-size: 100% 100%;
  margin-left: 15px;
  /* margin-top: 20px; */
}
#pgt-video {
  margin-top: 0px;
  height: 220px;
  /* background-color: yellow; */
}

#pgt-title {
  margin-top: 10px;
  color: white;
}

.el-divider--horizontal {
  margin: -15px 0;
}

#pgt-ul {
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;
  /* background: lemonchiffon; */
}

#pgt-ul li {
  text-align: left;
  /* margin-left: 50px; */
  height: 50px;
  line-height: 50px;
}

</style>
