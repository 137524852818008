<template>
    <div id="header">
        <img src="../assets/banner/banner_title.png" alt="">
        <div class="sub">
            <p>主办：洛阳市互联网信息办公室</p>
            <p v-html="'\u00a0'"></p>
            <p v-html="'\u00a0'"></p>
            <p v-html="'\u00a0'"></p>
            <p v-html="'\u00a0'"></p>
            <p v-html="'\u00a0'"></p>
            
            <p>承办：洛阳网</p>
        </div>
        <!-- <p id="title">洛阳互联网辟谣平台</p> -->
        <!-- <p id="sub-title">LUOYANG INTERNET ILLEGAL INFOMATION REPORTING ENTER</p> -->
    </div>
</template>

<script>
export default {
    name: 'PYHeader',
    components: {

    }
}
</script>

<style>
#header {
    position: relative;
    width: 100%;
    min-width: 1300px;
    height: 500px;
    background-image: url('../assets/banner/banner_bg.jpg') ;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}


#title {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 60px;
    margin: -80px 0 0 0px;
    font-size: 50px;
}

#sub-title {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 60px;
    margin: 0px 0 0 0px;
    font-size: 30px;
}

.sub {
    color: white;
    font-size: 25px;
    font-weight: bold;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
}

</style>