<template>
  <div id="py-header">
    <div id="header-box">
      <img id="title-img" :src="imgSrc" alt="">
      <el-button type="primary" @click="jumpToListPage">更多<i class="el-icon-arrow-right el-icon--right"></i></el-button>
    </div>
    <img id="line" src="../assets/home/home_line.png" alt="">
  </div>
</template>

<script>
import routerPath from '../router/routerpath' 
export default {
  props: {
    imgSrc: String,
    title: String,
    newsType: String
  },
  methods: {
    jumpToListPage() {
      this.$router.push({
          path: routerPath.qwfb,
          query: {
              title: this.title,
              newsType: this.newsType
          }
      })
    }
  }
}
</script>

<style scoped>
#py-header {
  width: 100%;
  height: 100px;
  /* background: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#header-box {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  /* background: cornflowerblue; */
}

#title-img {
  /* margin-left: 30px; */
  width: 314px;
  height: 56px;
}


#line {
  width: 100%;
}

</style>