import Vue from 'vue'
import VueRouter from 'vue-router'
import PYJBPage from '../views/PYJBPage.vue'
import PYJbTypePage from '../views/PYJbTypePage.vue'
import PYJbAnonymityPage from '../views/PYJbAnonymityPage.vue'
import PYJbRealPage from '../views/PYJbRealPage.vue'
import PYJbGuidePage from '../views/PYJbGuidePage.vue'
import PYNewsListPage from '../views/PYNewsListPage.vue'
import PYJbNewContext from '../views/PYJbNewContext.vue'
import PYHomePage from '../views/PYHomePage.vue'
import PYAddNewsPage from '../views/PYAddNewsPage.vue'
import routerPath from './routerpath'

Vue.use(VueRouter)

const router = new VueRouter({
    // mode: 'history',
    routes: [              //配置路由，这里是个数组
        {
            path: routerPath.index,
            name: 'PYHomePage',
            component: PYHomePage,
        },

        // 首页
        {
            path: routerPath.home,
            name: 'PYHomePage',
            component: PYHomePage,
        },
        // 权威发布
        {
            path: routerPath.qwfb,
            name: 'PYNewsListPage',
            component: PYNewsListPage,
        },
        // 辟谣专区
        {
            path: routerPath.pyzq,
            name: 'PYNewsListPage',
            component: PYNewsListPage,
        },

        // 辟谣作品展播
        {
            path: routerPath.pyzpzb,
            name: 'PYNewsListPage',
            component: PYNewsListPage,
        },

        // 辟谣样本库 -- 给爸妈的辟谣清单
        {
            path: routerPath.pyybk,
            name: 'PYNewsListPage',
            component: PYNewsListPage
        },
        
        // 以案说法
        {
            path: routerPath.yasf,
            name: 'PYNewsListPage',
            component: PYNewsListPage,
        },
        // 洛阳辟谣
        {
            path: routerPath.lypy,
            name: 'PYNewsListPage',
            component: PYNewsListPage,
        },
        // 新闻列表
        {
            path: routerPath.list,
            name: 'PYNewsListPage',
            component: PYNewsListPage,
        },
        // 新闻内容
        {   
            path: routerPath.content,
            name: 'PYJbNewContext',
            component: PYJbNewContext,
        },

        // 举报入口
        {                    //每一个链接都是一个对象
            path: routerPath.jbrk,         //链接路径
            name: 'PYJBPage',     //路由名称，
            component: PYJBPage   //对应的组件模板
        },
        // 举报类型
        {
            path: routerPath.jblx,
            name: 'PYJbTypePage',
            component: PYJbTypePage,
        },
        // 匿名举报
        {
            path: routerPath.nmjb,
            name: 'PYJbAnonymityPage',
            component: PYJbAnonymityPage
        },
        // 实名举报
        {
            path: routerPath.smjb,
            name: 'PYJbRealPage',
            component: PYJbRealPage
        },
        // 举报指南
        {
            path: routerPath.jbzn,
            name: 'PYJbGuidePage',
            component: PYJbGuidePage
        },

        // 添加信息
        {
            path: routerPath.tjxx,
            name: 'PYAddNewsPage',
            component: PYAddNewsPage
        }
    ]
})


export default router
