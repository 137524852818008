<template>
    <div id="py-home">
        <!-- <el-button id="py-home-btn" type="text"></el-button> -->
        <div id="home-header">
            <div id="home-left">
                <el-button id="py-home-btn" type="text" @click="openJbzn"></el-button>
                <div id="home-jbcx-box">
                    <div id="jbcx-box-title">
                        <img src="../assets/home/py_cx.png" alt="">
                        <p>举报查询</p>
                    </div>
                    <div id="divider">
                        <el-divider content-position="center"></el-divider>
                    </div>
                    <div id="btns-box"> 
                        <div><el-button type="primary" @click="openNewLin('https://www.henanjubao.com/jbcx/')" >举报受理结果查询</el-button></div>
                        <div><el-button type="primary" @click="openNewLin('http://press.nppa.gov.cn/')">新闻记者查询</el-button></div>
                        <div><el-button type="primary" @click="openNewLin('http://www.nppa.gov.cn/')">新闻出版机构查询</el-button></div>
                        <div><el-button type="primary" @click="openNewLin('http://www.nppa.gov.cn/')">发行机构查询</el-button></div>
                    </div>
                </div>
                <div id="home-jbbtn-box">
                    <!-- <el-button id="py-home-btn-phone" type="text"></el-button>
                    <el-button id="py-home-btn-app" type="text"></el-button> -->
                    <img src="../assets/py_jb_connect.png" alt="">
                </div>
            </div>
            <div id="home-right">
                <div id="right-jb-box">
                    <el-button id="right-jb-btn-sm" type="text" native-type="button" @click="linkToJBTypePage"></el-button>
                    <el-button id="right-jb-btn-nm" type="text" native-type="button" @click="linkToJBTypePage"></el-button>
                </div>
                <div id="right-divider">
                    <el-divider content-position="center">举报类型</el-divider>
                </div>
                <div id="right-type-box">
                    <div @click="linkToJBTypePage">
                        <img src="../assets/jb_types/type_zz.png" alt="">
                        <p>政治类</p>
                    </div>
                    <div @click="linkToJBTypePage">
                        <img src="../assets/jb_types/type_xg.png" alt="">
                        <p>疫情不实信息</p>
                    </div>
                    <div @click="linkToJBTypePage">
                        <img src="../assets/jb_types/type_bq.png" alt="">
                        <p>暴恐类</p>
                    </div>
                    <div @click="linkToJBTypePage">
                        <img src="../assets/jb_types/type_zp.png" alt="">
                        <p>诈骗类</p>
                    </div>
                    <div @click="linkToJBTypePage">
                        <img src="../assets/jb_types/type_db.png" alt="">
                        <p>赌博类</p>
                    </div>
                    <div @click="linkToJBTypePage">
                        <img src="../assets/jb_types/type_sq.png" alt="">
                        <p>色情类</p>
                    </div>
                    <div @click="linkToJBTypePage">
                        <img src="../assets/jb_types/type_yy.png" alt="">
                        <p>谣言类</p>
                    </div>
                    <div @click="linkToJBTypePage">
                        <img src="../assets/jb_types/type_bq.png" alt="">
                        <p>版权类</p>
                    </div>
                    <div @click="linkToJBTypePage">
                        <img src="../assets/jb_types/type_qt.png" alt="">
                        <p>其他类</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="home-banner">
            <img src="../assets/home/py_home_banner.png" alt="" @click="openNewLin('https://www.piyao.org.cn/2020yqpy/')">
        </div>
        <div id="home-info">
            <!-- <div id="home-info-left">
                <img src="../assets/home/hoem-info-wxjb.png" alt="">
                <img src="../assets/home/home-info-wz.png" alt="">
            </div> -->
            <div id="home-info-right">
                <PYList title="网信信息" newType="wxxx"></PYList>
                <PYList title="通知公告" newType="tzgg"></PYList>
                <PYList title="安全警示" newType="aqyj"></PYList>
                <PYList title="法律法规" newType="fl"></PYList>
            </div>
        </div>
        <el-divider></el-divider>
        <!-- 举报机构 -->
        <div id="home-jbjg">
            <p>相关举报机构</p>
            <div>
                <el-button class="jb-btn" type="primary" @click="openNewLin('http://www.cyberpolice.cn/wfjb/')">公安部: 110</el-button>
                <el-button class="jb-btn" type="primary" @click="openNewLin('https://www.12321.cn/')">工信部: 12381</el-button>
                <el-button class="jb-btn" type="primary" @click="openNewLin('https://jbts.mct.gov.cn/')">文化和旅游部: 12318</el-button>
                <el-button class="jb-btn" type="primary" @click="openNewLin('http://www.shdf.gov.cn/shdf/channels/740.html')">"扫黄打非"办公室: 12390</el-button>
                <el-button class="jb-btn" type="primary" @click="openNewLin('http://www.cac.gov.cn/')">中央网信办</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import PYList from '../components/PYList'
import routerpath from '../router/routerpath'
export default {
    components: { PYList },
    created: function() {
        console.log(this.$router)
    },
    methods: {
        linkToJBTypePage() {
            // 跳转到举报类型页面
            this.$router.push(routerpath.jblx)
        },
        openNewLin(url) {
            window.open(url, '_blank')
        },
        openJbzn() {
            // 打开举报指南
            this.$router.push(routerpath.jbzn)
        }
    },
}
</script>

<style scoped>
p{ padding:0;margin:0;list-style:none}

#home-jbjg {
    width: 1290px;
    height: 200px;
    /* background: blue; */
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 35px;
    /* font-weight: bold; */
    color: #3587FF;
}

#home-jbjg div {
    width: 100%;
    height: 150px;
    /* background: cornsilk; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.jb-btn {
    height: 100px !important;
    background: white !important;
    color: #3587FF !important;
}

#home-info {
    width: 1260px;
    height: 650px;
    /* background: chartreuse; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

#home-info-left {
    width: 369px;
    height: 100%;
    /* background: coral; */
}

#home-info-left img {
    margin-bottom: 20px;
}

#home-info-right {
    width: 1260px;
    height: 100%;
    /* background: crimson; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    align-content: space-between;
}

#home-right {
    width: 870px;
    height: 900px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#right-jb-box {
    height: 230px;
    width: 800px;
    /* background: red; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#right-jb-btn-sm {
    width: 300px;
    height: 180px;
    background: url(../assets/py-nmjb.png) center no-repeat;
    background-size: cover;
    color: rgba(1, 1, 1, 0);
}

#right-jb-btn-nm {
    width: 300px;
    height: 180px;
    background: url(../assets/py-smjb.png) center no-repeat;
    background-size: cover;
    color: rgba(1, 1, 1, 0);
}

#right-divider {
    height: 60px;
    width: 830px;
    /* background: yellow; */
    margin: 0 20px;
}

#right-type-box {
    width: 870px;
    height: 610px;
    /* background: yellowgreen; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

#right-type-box div{
    height: 160px;
    width: 230px;
    /* background: coral; */
    -webkit-box-shadow:0 0 5px 2px #DAE9F5;  
    -moz-box-shadow:0 0 5px 2px #DAE9F5;  
    box-shadow:0 0 5px 2px #DAE9F5;  
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
}

#py-home {
    /* height: 900px;*/
    width: 1300px;
    margin: 0 auto; 
    /* background-color: #EAEEEF; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#home-banner {
    margin-top: 30px;
    margin-bottom: 30px;
}

#home-header {
    height: 900px;
    width: 1300px;
    margin: 0 auto;
    /* background-color: #EAEEEF; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#home-left {
    width: 430px;
    height: 900px;
    margin-top: 0;
    background: #EAEEEF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#py-home-btn {
    width: 363px;
    height: 100px;
    background: url(../assets/jbzn.png) center no-repeat;
    background-size: cover;
    color: rgba(1, 1, 1, 0);
    margin: 20px 0;
}

#home-jbcx-box {
    width: 363px;
    height: 400px;
    margin: 30px 0 10px 0;
    background: white;
    margin: 20px 0;
    border: 2px #94C5FF solid;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

#home-jbbtn-box {
    width: 363px;
    height: 260px;
    /* background: blue; */
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: ccenter;
    align-items: center;
}

#home-jbbtn-box img {
    width: 363px;
    /* height: 200px; */
}

#py-home-btn-phone {
    width: 170px;
    height: 170px;
    background: url(../assets/jb_phone.png) center no-repeat;
    background-size: cover;
    color: rgba(1, 1, 1, 0);
}

#py-home-btn-app {
    width: 170px;
    height: 170px;
    background: url(../assets/jb_app.png) center no-repeat;
    background-size: cover;
    color: rgba(1, 1, 1, 0);
}

#jbcx-box-title {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #0051DF;
}

#jbcx-box-title {
    width: 200px;
}

#jbcx-box-title img {
    width: 40px;
    height: 53px;
}

#divider {
    margin-left: 20px;
    margin-right: 20px;
    width: 280px;
}

#btns-box {
    margin: auto 20px;
    margin-bottom: 20px;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}



.el-button {
    width:290px;
    height: 65px;
}

.el-divider__text {
    font-size: 30px;
}
.el-divider--horizontal {
    height: 2px;
    margin: 10px 0;
}



</style>