<template>
    <el-row id="py-nav-menu"  :gutter="20">
        <el-col :span="12" :offset="6">
            <el-row :gutter="20">
                <el-menu :default-active="activeIndex"
                    mode="horizontal" @select="handleSelect"
                    background-color="#0B40A1"
                    text-color="lightgray"
                    active-text-color="#fff">
                    <el-col :span="3"><el-menu-item index="1">首页</el-menu-item></el-col>
                    <el-col :span="3"><el-menu-item index="2">权威发布</el-menu-item></el-col>
                    <el-col :span="3"><el-menu-item index="3">谣言曝光台</el-menu-item></el-col>
                    <el-col :span="3"><el-menu-item index="4">以案说法</el-menu-item></el-col>
                    <el-col :span="3"><el-menu-item index="5">法律法规</el-menu-item></el-col>
                    <el-col :span="3"><el-menu-item index="7">洛阳辟谣</el-menu-item></el-col>
                    <el-col :span="3"><el-menu-item index="6">举报入口</el-menu-item></el-col>
                </el-menu>
            </el-row>
        </el-col>
    </el-row>
</template>

<script>
import routerPath from '../router/routerpath'
export default {
    data: function() {
        return {
            activeIndex: "1"
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            // console.log(key, keyPath)
            this.activeIndex = '' + keyPath
            switch (this.activeIndex) {
                case '1': 
                    // 跳转到首页
                    if (this.$route.path !== routerPath.home) this.$router.push(routerPath.home)
                    break
                case '2':
                    // 跳转到权威发布
                    if (this.$route.path !== routerPath.qwfb) {
                        this.$router.push({
                            path: routerPath.qwfb,
                            query: {
                                title: '权威发布',
                                newsType: 'qwfb'
                            }
                        })
                    }
                    break
                case '3':
                    window.open('https://www.piyao.org.cn/yybgt/index.htm', '_blank')
                    break
                case '4':
                    if (this.$route.path !== routerPath.yasf) {
                        this.$router.push({
                            path: routerPath.yasf,
                            query: {
                                title: '以案说法',
                                newsType: 'jsal'
                            }
                        })
                    }
                    break
                case '5':
                    // 跳转到法律法规
                    if (this.$route.path !== routerPath.list) {
                        this.$router.push({
                            path: routerPath.list,
                            query: {
                                title: '法律法规',
                                newsType: 'fl'
                            }
                        })
                    }
                    break
                case '6':
                    if (this.$route.path !== routerPath.jbrk) this.$router.push(routerPath.jbrk)
                    break
                case '7':
                    // 跳转到法律法规
                    // if (this.$route.path !== routerPath.list) {
                    //     this.$router.push({
                    //         path: routerPath.list,
                    //         query: {
                    //             title: '洛阳辟谣',
                    //             newsType: 'lypy'
                    //         }
                    //     })
                    // }
                    window.open('http://mobile.lyd.com.cn/news4/system/2020/01/26/031565598.shtml', '_blank')
                    break
                default:
                    break
            }
        }
    }
}
</script>

<style>
#py-nav-menu {
    background-color: #0B40A1;
    height: 56px;
    min-width: 1300px;
}

.el-menu-item {
    text-align: center;
}

</style>