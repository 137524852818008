const routerPath = {
    // 
    index: '/',

    // 首页
    home: '/home',

    // 权威发布:
    qwfb: '/qwfb',

    // 以案说法
    yasf: '/yasf',

    // 辟谣平台
    pyzq: '/pyzq',

    // 辟谣作品展播
    pyzpzb: '/pyzpzb',

    // 辟谣样本库 -- 给父母的辟谣清单
    pyybk: '/pyybk',

    // 新闻列表
    list: '/list',
    // 新闻内容:
    content: '/new',

    // 举报入口
    jbrk: '/jbrk',
    // 举报类型
    jblx: '/jblx',
    // 匿名举报
    nmjb: '/nmjb',
    // 实名举报
    smjb: '/smjb',
    // 举报指南
    jbzn: '/jbzn',

    // 洛阳辟谣
    lypy: '/lypy',

    // 添加信息
    tjxx: '/tjxnzz'
}

export default routerPath