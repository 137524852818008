// 匿名举报页面
<template>
  <div id="py-nm" v-loading="fullscreenLoading">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
      size="medium"
    >
      <el-divider>举报信息</el-divider>
      <el-form-item label="选择类型" prop="type">
        <el-select v-model="ruleForm.type" placeholder="请选择举报类型">
          <el-option label="权威发布" value="qwfb"></el-option>
          <el-option label="法律" value="fl"></el-option>
          <el-option label="警示案例" value="jsal"></el-option>
          <el-option label="知识百科" value="bkzs"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="新闻名称" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="新闻源网址" prop="link">
        <el-input v-model="ruleForm.link"></el-input>
      </el-form-item>
      <el-form-item label="新闻来源" placeholder="例如:洛阳网" prop="source">
        <el-input v-model="ruleForm.source"></el-input>
      </el-form-item>
      <el-form-item label="活动时间" prop="time">
        
          <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date1" style="width: 100%;"></el-date-picker>
       
      </el-form-item>
      <!-- <el-form-item label="举报标题" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item> -->
      <el-form-item label="新闻内容">
        <el-input type="textarea" v-model="ruleForm.article"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const typeDic = {
  'qwfb': '权威发布',
  'fl': '法律',
  'jsal': '警示案例',
  'zsbk': '知识百科',
}

export default {
  data() {
    return {
      ruleForm: {
        type: "",
        title: "",
        link: "",
        article: "",
        date1: "",
        source: ""
      },
      rules: {
        type: [
          { required: true, message: "请选择举报类型", trigger: "change" },
        ],
        title: [
          { required: true, message: "请输入新闻名称", trigger: "blur" },
        ],
        source: [
          { required: true, message: "请输入新闻名称", trigger: "blur" },
        ],
        link: [
          { required: true, message: "请输入新闻源网址", trigger: "blur" },
        ]
      },
      fullscreenLoading: false
    };
  },
  methods: {
    
    submitForm(formName) {
      Date.prototype.format = function(fmt) { 
          var o = { 
              "M+" : this.getMonth()+1,                 //月份 
              "d+" : this.getDate(),                    //日 
              "h+" : this.getHours(),                   //小时 
              "m+" : this.getMinutes(),                 //分 
              "s+" : this.getSeconds(),                 //秒 
              "q+" : Math.floor((this.getMonth()+3)/3), //季度 
              "S"  : this.getMilliseconds()             //毫秒 
          }; 
          if(/(y+)/.test(fmt)) {
                  fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
          }
          for(var k in o) {
              if(new RegExp("("+ k +")").test(fmt)){
                  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
              }
          }
          return fmt; 
      }  
      if (this.ruleForm.date1 == '') {
        alert('请选择时间');
        return
      }
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log("this.ruleForm.date1：", this.ruleForm.date1)
          let oldTime = (new Date(this.ruleForm.date1 )).getTime();
          // console.log('old Time：', oldTime)
          let curTime = new Date(oldTime).format("yyyy-MM-dd");
          // console.log('cur: ',curTime);
          let obj = { 
            title: this.ruleForm.title,
            time: curTime,
            link: this.ruleForm.link,
            source: '来源：' + this.ruleForm.source,
            article: this.ruleForm.article,
            type: this.ruleForm.type,
            type_name: typeDic[this.ruleForm.type]
          }
          console.log('obj: ', obj)
          this.fullscreenLoading = true;
          
          setTimeout(() => {
            this.fullscreenLoading = false;
          }, 2000);
          
          this.axios.post(`/addnew`, obj)
          .then(res => {
              console.log('res: ', res)
              if (res.data)
              this.fullscreenLoading = false;
              this.$refs[formName].resetFields();
          })
          .catch(() => {
              this.fullscreenLoading = false;
              alert('提交失败')
          }) 
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleChange() {

    },
    goBack() {
      console.log('go back');
      this.$router.go(-1)
    },
  },
};
</script>

<style scoped>
#py-nm {
  width: 100%;
  height: 800px;
  /* background: yellowgreen; */
}

.el-page-header {
  width: 700px;
  margin: 20px auto;
}

form {
  width: 700px;
  height: 100%;
  margin: 50px auto;
}
</style>
